* {
  padding:0;
  margin:0;
  box-sizing: border-box;
}

nav, footer, header, aside { display:block; }

a {
  text-decoration: none;
}

a:not(.button) {
  transition: .3s;
  color: var(--link-color);
}

a:hover:not(.button) {
  color: #60beef;
}

input, button, textarea {
  font-family: inherit;
}

button {
  cursor: pointer;
  border: 0;
  user-select: none;
}

p {
  line-height: 1.5em;
}

p:not(:last-child) {
  margin-bottom: 1em;
}

ul {
  padding-left: 40px;
}
