::-webkit-scrollbar {
  width: 5px;
  height: 0px;
}

::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1);
}

::-webkit-scrollbar-thumb {
  background-color: rgba(169, 169, 169, 0.5);
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(136, 136, 136, 0.5);
}
