.description {
  display: grid;
  gap: 1em;
}

.description__history-list > li{
  padding: 0.25em 0em;
}

.description__main {}

@media screen and (min-width: 990px) {
  .description__main {
    font-size: 1.2em;
  }
}