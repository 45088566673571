table {
  border-collapse: collapse;
}
table thead {
  height: var(--height);
  background-color: #F5F6F7;
}
table tbody {
  background-color: #FFF;
}

table th, table td {
  border: 1px solid var(--border-color);
  padding: 6px 12px;
}
table th {
  font-weight: bold;
}

.table-info {
  display: grid;
  gap: .5em;
  position: relative;
}

.table-wrapper {
  overflow: auto;
}
.table-info .date-column {
  min-width: 140px;
}

.table-info table {
  font-size: 0.75em;
  line-height: 1.3em;
  width: 100%;
}

.table-info__download {
  justify-self: end;
  width: max-content;
  position: sticky;
  top: 0.5em;
  left: 0px;
}