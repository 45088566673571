.button {
  position: relative;
  display: inline-block;
  padding: 0.75em 1em;
  border-radius: 0.25rem;
  font-size: 1.1em;
  font-weight: bold;
  color: white;
  transition: 0.3s;
}

.button[disabled] {
  opacity: .5;
  cursor: not-allowed;
}

.button.primary {
  background-color: var(--primary-color);
}
.button.primary:hover:not([disabled]) {
  background-color: var(--primary-hover-color);
}
.button.primary:active:not([disabled]) {
  background-color: var(--primary-active-color);
}
.button.primary-invert {
  background-color: var(--primary-color);
}
.button.primary-invert:hover:not([disabled]) {
  background-color: var(--secondary-color);
}
.button.primary-invert:active:not([disabled]) {
  background-color: var(--secondary-active-color);
}

.button.secondary {
  background-color: var(--secondary-color);
}
.button.secondary:hover:not([disabled]) {
  background-color: var(--secondary-hover-color);
}
.button.secondary:active:not([disabled]) {
  background-color: var(--secondary-active-color);
}

.button.secondary-invert {
  background-color: var(--secondary-color);
}
.button.secondary-invert:hover:not([disabled]) {
  background-color: var(--primary-color);
}
.button.secondary-invert:active:not([disabled]) {
  background-color: var(--primary-active-color);
}