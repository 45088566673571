.footer {
  background: #64748B;
  color: white;
  padding: 1em 0px;
}

.footer__container {
    display: flex;
    justify-content: space-between;
}

.footer__title {
  display: inline-block;
  font-size: 1.2em;
  margin-bottom: 0.5em;
}

ul.footer__contacts {
  padding: 0;
  list-style:none;
}

.footer__contacts > li {
  padding: 0.25em 0em;
}

.footer__copyright {
  font-size: 0.75em;
  align-self: flex-end;
}

.footer a {
  color: #fff !important;
  text-decoration: underline;
}

.footer a:hover {
  color: var(--link-hover-color) !important;
}