@import 'description.css';
@import 'chart.css';
@import 'table.css';

.main {
  padding-top: 1em;
  padding-bottom: 1em;
}

.main > section:not(:last-child) {
  padding-bottom: 1em;
}
