html, body {
  --height: 38px;
  --height-header: 46px;

  --primary-bg-color: #F9F9FB;
  --primary-text-color: #474D66;
  --border-color: #E4E7EB;

  --link-color: #039BE5;
  --link-hover-color: #60BEEF;

  --primary-color: #FB923C;
  --primary-hover-color: #FDBA74;
  --primary-active-color: #F97316;

  --secondary-color: #14B8A6;
  --secondary-hover-color: #2DD4BF;
  --secondary-active-color: #0D9488;

  --success-color: #10B981;
  --warning-color: #FAAD14;
  --danger-color: #EF4444;

  /* breakpoints from material-ui */
  --xs-screen: 0px;
  --sm-screen: 600px;
  --md-creen: 900px;
  --lg-screen: 1200px;
  --xl-screen: 1536px;
}